import React from "react";
import { Section, SubSection } from "~components/text-helpers";
import Latex from "react-latex";
import { graphql, Link } from "gatsby";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import { css } from "@emotion/react";
import { TextHeader } from "~components/header";
import { Emoji } from "emoji-mart";

import { Menu, Table } from "antd";
const { SubMenu } = Menu;
import color from "~styles/color";

import Footer from "~components/footer";

import {
  Code,
  Response,
  Attribute,
  IL,
  Remark,
  VSpace,
  SideBySideCode,
  APIReferenceiTHOR,
} from "~components/documentation";

export default function iTHORDocReference({ data, location }) {
  return (
    <APIReferenceiTHOR columnKey="installation" pageName="Installation">
      <Section sectionTitle="Installation" emoji="computer">
        <p>
          iTHOR comes packaged with AI2-THOR, which can be installed in Python
          using pip:
        </p>
        <Code language="bash">pip install ai2thor</Code>
        <p
          css={css`
            margin-top: 10px;
          `}
        >
          or conda:
        </p>
        <Code language="bash">conda install -c conda-forge ai2thor</Code>
        <p
          css={css`
            margin-top: 10px;
          `}
        >
          Optionally, one may also choose to install AI2-THOR from an exact{" "}
          <a
            href="https://github.com/allenai/ai2thor/commits/main"
            target="_blank"
          >
            Git commit
          </a>
          . Here, we can pass in the commit id to:
        </p>
        <Code
          language="bash"
          lines={[
            "pip install --extra-index-url https://ai2thor-pypi.allenai.org ai2thor==0+COMMIT_ID",
          ]}
        />
        <p
          css={css`
            margin-top: 10px;
          `}
        >
          For example, we may have:
        </p>
        <Code
          language="bash"
          lines={[
            "# Example commit id: d74c4a9bf9ec558f27d4bd1779d5e33983b99db0",
            "pip install --extra-index-url https://ai2thor-pypi.allenai.org ai2thor==0+d74c4a9bf9ec558f27d4bd1779d5e33983b99db0",
          ]}
        />
      </Section>
      <Section sectionTitle="First Steps!" emoji="baby">
        <p>
          To verify that your setup is correct, try initializing a{" "}
          <IL>Controller</IL> and executing an action:
        </p>
        <Code
          lines={[
            "from ai2thor.controller import Controller",
            "controller = Controller()",
            'event = controller.step("MoveAhead")',
          ]}
        />
        <p
          css={css`
            margin-top: 10px;
          `}
        >
          The first time a <IL>Controller</IL> is initialized, the game
          environment containing the 3D scenes will be locally downloaded to{" "}
          <IL>~/.ai2thor</IL>. The size of the download is approximately 500MB.
        </p>
      </Section>
      <Section sectionTitle="Headless Setup" emoji="hear_no_evil">
        <p>
          By default AI2-THOR currently opens up a Unity window each time a{" "}
          <IL>Controller</IL> is initialized. However, AI applications often do
          not need a window that renders the on-screen view and administrators
          of compute clusters might restrict the use of Xorg on the machines due
          to security concerns and the large number of dependencies that make
          the updates inconvenient.
        </p>
        <p>
          Therefore, we have been worked with Unity to enable headless
          (off-screen) rendering of AI2-THOR. Headless mode can be enabled by
          setting the <IL>platform</IL> to <IL>CloudRendering</IL> upon
          initialization:
        </p>
        <Code
          lines={[
            "from ai2thor.controller import Controller",
            "from ai2thor.platform import CloudRendering",
            "controller = Controller(platform=CloudRendering)",
          ]}
        />
      </Section>
      <Section sectionTitle="Google Colab" emoji="blossom">
        <p>
          To get started with AI2-THOR, you may find it useful to use{" "}
          <a href="https://github.com/allenai/ai2thor-colab">AI2-THOR Colab</a>.
          It enables you to run AI2-THOR on Google Cloud for free in a browser,
          without any setup on your local device!
        </p>
        <Remark>
          By default, AI2-THOR does not work out of the box with Google Colab,
          as it requires the use of Xorg for AI2-THOR to work.
        </Remark>
      </Section>
      <Section sectionTitle="Docker" emoji="whale">
        <p>
          For users that prefer Docker, we provide{" "}
          <a href="https://github.com/allenai/ai2thor-docker" target="_blank">
            AI2-THOR Docker
          </a>{" "}
          as a mini-framework to simplify the setup.
        </p>
      </Section>
      <Section sectionTitle="Requirements" emoji="dart">
        <Table
          css={css`
            th {
              display: none;
            }
          `}
          pagination={false}
          columns={[
            {
              title: "Type",
              dataIndex: "requirementType",
              key: "requirementType",
            },
            {
              title: "Requirement",
              dataIndex: "requirement",
              key: "requirement",
            },
          ]}
          dataSource={[
            {
              key: "OS",
              requirementType: "OS",
              requirement: "macOS 10.9+ or Ubuntu 14.04+",
            },
            {
              key: "Python",
              requirementType: "Python",
              requirement: "Python 3.5+",
            },
            {
              key: "CPU",
              requirementType: "CPU",
              requirement: "SSE2 instruction set support",
            },
            {
              key: "Graphics Card",
              requirementType: "Graphics Card",
              requirement:
                "DX9 (shader model 3.0) or DX11 with feature level 9.3 capabilities",
            },
            {
              key: "Linux Users",
              requirementType: "Linux Users",
              requirement: "X server with GLX module enabled",
            },
          ]}
        />
      </Section>
    </APIReferenceiTHOR>
  );
}

export const query = graphql`
  fragment FluidImage on File {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  query {
    horizon: file(relativePath: { eq: "horizon-angles.jpg" }) {
      ...FluidImage
    }
    dimensions: file(relativePath: { eq: "dimensions.jpg" }) {
      ...FluidImage
    }
  }
`;
